import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Graph = styled.div`
  margin: ${g(4)} 0;
`

export const Title = styled.div`
  display: flex;
  margin-bottom: ${g(3)};
`
